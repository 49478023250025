(function ($) {
    $(".woocommerce-Address header a.edit:contains('Add')").closest('.woocommerce-Address').addClass("__add");

    $(".checkout_coupon .form-row-last .button").text($(".checkout_coupon .form-row-last .button").text().replace("Apply coupon", "Apply"));

    $(".cart-empty").prepend("<span class='icon icon-alert-2'></span>");

    $("button[name='save_account_details']").prepend("<span class='icon icon-save'></span>")

    /* Star rating */
    var starRating = $('.star-rating .rating');
    if (starRating.innerHTML = '1') { starRating.parent().parent().addClass('__one'); }
    if (starRating.innerHTML = '2') { starRating.parent().parent().addClass('__two'); }
    if (starRating.innerHTML = '3') { starRating.parent().parent().addClass('__three'); }
    if (starRating.innerHTML = '4') { starRating.parent().parent().addClass('__four'); }
    if (starRating.innerHTML = '5') { starRating.parent().parent().addClass('__five'); }

    /* Payment method */
    var addPaymentButton = $('.woocommerce-MyAccount-content .button');
    var confirmPaymentButton = $('.woocommerce-MyAccount-content .form-row .button');

    if (addPaymentButton.innerHTML = 'Add payment method') {
        addPaymentButton.addClass('__addpaymentmethod');
    }

    if (confirmPaymentButton.innerHTML = 'Add payment method') {
        confirmPaymentButton.html('Confirm payment method').addClass('__tick __icononly');
    }

    /* Product enquiry form page */
    if (window.location.href.indexOf("?enquiry_product_id=") > -1) {
        $('.page').addClass('__product-enquiry-form');
    }

    if ($('.banner.__no-bg').length || $('.banner.__dark-text').length && !window.location.hash) {
        $('.breadcrumb').addClass('__no-banner-bg');
    }

    if ($('.banner .default-background').length && !window.location.hash) {
        $('.breadcrumb').addClass('__no-banner-bg');
    }

    /* Wholesale login page */
    $(function() {
        var url = location.pathname;
        var wooInfo = $('.woocommerce-info');
        var wooColumns = $('.woocommerce .u-columns');
        var wooNotices = $('.woocommerce-notices-wrapper');

        if (url.indexOf('wholesale-login') > -1) {
            wooInfo.addClass('__wholesale-info');
            wooColumns.addClass('__wholesale-login');
            wooNotices.addClass('__wholesale-notices');
        }
    });

    $(window).on("load", function () {
        setTimeout(function () {
            $('.woocommerce')
                .find('.checkout-collaterals .shop_table tr.order-total .includes_tax, .product-cart-collaterals .shop_table .order-total td .includes_tax')
                .each(function () {
                    let text = $(this).text();
                    $(this).text(text.replace('(includes', '(incl.'));
                });
        }, 300);
    });

    var update_cart = $('.product-cart-items').find('.update-cart');

    update_cart.on('click', function (event) {
        setTimeout(function () {
            $('.woocommerce').find('.product-cart-collaterals .shop_table .order-total td .includes_tax')
            .each(function () {
                let text = $(this).text();
                $(this).text(text.replace('(includes', '(incl.'));
            });
        }, 1000);
    })

})(jQuery);